
  

body {
	font-family:'futura-pt', sans-serif;
	color:$theme-txt-color;

}


h1,h2,h3,h4,h5,h6 {
	margin:0;
	padding: 0;
	font-weight: 800;
}

