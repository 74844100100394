@import 'reset';
@import 'theme';
@import 'mixins';
@import 'breakpoints';
@import 'colors';
@import 'typography';
@import 'cardFlip';


$v-spacer: 3.3125rem;
$h-spacer: 1.25rem;
* {

}

html {
	background: $theme-bg-color;
	scroll-behavior: smooth;
	overflow-x: hidden;
}



header {
	width: 375px;
	margin: 0 auto;
	padding: 2rem;
}

footer {
	text-align: right;
	font-size:0.75rem;
	opacity:0.4;
}

svg{
  font:700 96px 'futura-pt', serif;
  width: 95%;
  transition: opacity 2s;
}

svg>text{
  fill: none;
  stroke: #fff;
  stroke-width: 2px;
  stroke-linejoin: round;
  text-transform: uppercase;

}

section {
	margin-bottom: 2rem;
}

#profile {
	max-width: 500px;
	min-width: 375px;
	width:100%;
	margin:0 auto;
	padding: 0 2rem;
	// display: grid;
	// gap:2rem;

	& h1 {
		font-size: 2rem;
		font-weight: 600;
	}

	& h4 {

		font-size: 1rem;
		font-weight: 200;
		opacity: 0.5;

	}


	&  a {
		display: block;
		color:#fff;
		text-decoration: none;
		border: 2px solid #fff;
		padding: 0.5rem 1rem;
		transition: all 150ms;

		& i {
			margin-right: 0.5rem;
		}

	}



}

#profile-picture {
	position: relative;
	width: 100%;
	
	& img {
		position: relative;
		width: 100%;
		display: block;
		z-index:-1;
	}

	& svg {
		position: absolute;
		bottom: 0;
		right:0;
		z-index:1;

	}
}


#profile-links {
	& ul {
		
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap:1rem;
		& li:first-child {
			grid-column: 1/3;
			&>a {
				grid-template-columns: 1rem minmax(0, 1fr);
			}
		}
		& li:nth-child(2){
			grid-column: 1/-1;
			&>a {
				grid-template-columns: 1rem minmax(0, 1fr);
			}
		}

	}
	& li>a {

		font-size: clamp(0.85rem, 0.3182rem + 2.1818vw, 1rem);

		display: grid;
		grid-template-columns: 1rem 1fr;
		align-items: center;
		&:hover {
			transform: scale(1.1);
			transition: 250ms all;
		}
	}

	& li>a>span {
		margin-left: 1rem;
	}
}

footer {
	margin-top: 2rem;
	padding: 1rem;
}


#staff-directory {
	max-width: 500px;
	margin: 0 auto;
	padding: 0 2rem;

	& ul {
		display: grid;
		gap:1rem;
		margin:1rem 0;
		& a{
			display: block;
			color:white;
			text-decoration: none;
			border:1px solid white;
			padding: 0.5rem 1rem;

			&:hover {
			transform: scale(1.1);
			transition: 250ms all;
		}
		}
	}
}

.credit-in-source {
	opacity: 0;
}

#directory-link {
	outline: none !important;
	opacity: 0.5;
	// border: none !important;
	text-align: right;
	padding: 0.25rem !important;
	margin-inline: 0 !important;
	margin-block: 1rem;
	&:hover, :focus {
		opacity: 1;
	}
}






























